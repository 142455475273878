<section class="section jumbotron-2 headerindex" style="position: fixed; width: 100%; z-index: 1000; display: block;">
  <div class="jumbotron-2-content context-dark bg-image">
    <div class="rd-navbar-wrap" style="height: 113.391px;">
      <nav class="rd-navbar rd-navbar-modern rd-navbar-original rd-navbar-static">
        <div class="rd-navbar-main-outer">
          <div class="rd-navbar-main rd-navbar-main1">
            <div class="rd-navbar-panel">
              <div class="rd-navbar-brand"><a href="index.html" class="brand index"><img
                    src="assets/images/logo-inverse-316x92.png" alt="" width="158" height="46"
                    class="brand-logo-dark"><img src="assets/images/logo-default-316x92.png" alt="" width="158"
                    height="46" class="brand-logo-light"></a></div>
            </div>
            <div class="rd-navbar-nav-wrap">
              <ul class="rd-navbar-nav" style="    display: flex;
              flex-flow: row;
              justify-content: flex-end;">
                <li class="rd-nav-item aboutmenu isnotmobile"><a href="/auth/login"
                    class="rd-nav-link">Autentificare/Inregistrare</a></li>
                <li class="rd-nav-item aboutmenu isnotmobile"><a href="/blog/index.html" 
                      class="rd-nav-link">Blog</a></li>
                <li class="rd-nav-item aboutmenu ismobile" style="    padding-right: 30px;"><a href="/auth/login"
                    class="rd-nav-link">Cont</a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</section>

<ng-container>

  <section class="section jumbotron-2 home-bg">
    <div class="jumbotron-2-content context-dark bg-image">

      <div class="container" style="padding-top: 75px;">
        <div class="jumbotron-2-content-inner">
          <div class="row justify-content-start">
            <div class="col-xl-5 textmobile" style="padding: 0px;">
              <h1 class="text-white" style="color: orangered !important;">PRECIS.ONE</h1>
              <!-- <div class="col-md-2 col-md-offset-5 imgfree"> <img src="assets/images/free2.png" alt=""> </div> -->
              <p class="text-white-8 text-md intro-subtitle ls-ng-1" style="padding-top: 10px;">Cunoaste persoane
                compatibile!<br>
                <!-- <span style="font-size: 17px;">Prieteni, Relaxare, Excursii, Calatorii, Iesiri, Socializare</span> -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lgx bg-default text-center" style="margin-top: 50px;">
    <div class="container col-md-12 divmobile" style=" 
    display: flex;
    margin: 0px;">

      <div class="jumbotron-2-blockx col-md-6 col-12" style="padding: 30px 20px 20px 20px;
      /* background: #e0f3f1; */
      border-radius: 15px;">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Cunoaste persoane compatibile!</h2>
          <p class="big"> 

            Accesează secțiunea <a [routerLink]="['/users']" style="color: blue;">Membri</a> pentru a da Like persoanelor <br class="isnotmobile"> posibil compatibile. După ce au fost selectate persoanele care au primit <span style="color: orangered;">Like reciproc</span>, va fi organizat un eveniment cu un număr egal de <br class="isnotmobile"> fete și băieți.
          </p>
          <!-- <div class="row fields">
            <div class="col-md-4 offset-md-4"><a class="btn btn-info" href="blog/evenimente.html" target="_blank"
                style="background: cornflowerblue; border-radius: 50px; margin-top: -15px;">Mai multe detalii</a>
            </div>
          </div> -->
        </div>
      </div>

      <div class="jumbotron-2-blockx col-md-6 col-12">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <img src="assets/images/events.jpg" style="    display: inline-block;
    max-width: 100%;
    padding: 0px 10px;
    border-radius: 15px;" />
        </div>
      </div>

    </div>
  </section>


  <section class="section" style="background: #fafafa; padding: 50px; display: none;">
    <div class="range">
      <div class="col-md-12">
        <div class="cell-inner section-lg2 text-center text-sm-left">
          <h2 class="text-center text-xl-left wow fadeIn">Cunoaste persoane noi! <br> <span style="font-size: 25px;">-
              prietenie sau relatie -</span></h2>
          <!-- <p class="text-center text-xl-left wow fadeIn" data-wow-delay=".05s">
            Identificam pentru evenimente persoane noi si compatibile
          </p> -->
          <!-- Persoanele care nu raspund intr-o saptamana, vor avea contul blocat si va trebui sa achite o taxa pentru deblocare. -->
          <!-- Owl Carousel-->
          <div class="custom owl-1 list-group-1 mt-lg-50 row col-md-12x">
            <article class="col-md-4 wow fadeIn">
              <div class="icon lg-1-item-icon mdi material-design-compass106"></div>
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Cautare</h3>
                <p>Ca sa putem identifica persoane compatibile, este necesar sa completati profilul. Cautam atat in baza
                  de
                  date cat si prin partenerii nostri.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn">
              <div class="icon lg-1-item-icon mdi mdi-account-search"></div>
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Referinte</h3>
                <p>Pentru fiecare persoana care isi creaza cont, folosind link-ul Dvs. de referinta din profil, ai
                  <span style="color: orangered;">reducere 10 RON la evenimentele partenerilor</span>.
                </p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn" data-wow-delay=".05s">
              <div class="icon lg-1-item-icon mdi mdi-comment-processing"></div>
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Evenimente</h3>
                <p>Incurajăm persoanele active și sprijinim organizatorii de evenimente. Prin implicarea lor contribuie
                  la succesul fiecărui moment special.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="breadcrumbs-customx" style="background-image: url(assets/images/bg-image-2.jpg); background-size: cover; background-position: bottom; color: white; text-align: center;">
    <div class="breadcrumbs-custom-inner" style="padding: 50px 0px !important;">
      <div class="container">
        <div class="breadcrumbs-custom-main">
          <h2 class="breadcrumbs-custom-title" style="color: white !important;">2k1</h2>
          <p class="big">Oferim gratuit serviciile noastre in aceasta perioada! <br> Mai mult, daca esti o persoana potrivita pachetului 2k1, <br> serviciile noastre vor fi mereu gratuite (contacteaza un reprezentant)!
            <a href="https://www.facebook.com/VreauRelatieSerioasa" target="_blank"><i class="fa fa-facebook-official" style="color: cornflowerblue;margin-left: 10px;left: 50%;transform: translateX(-50%);"></i></a>
          </p>
        </div>
        <div class="row fields">
        <div class="col-md-4 offset-md-4">
          <button class="btn btn-info" [routerLink]="['/profile']"
            style="background: lightseagreen; border-radius: 50px; margin-top: -15px;">Completeaza profilul</button>
        </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- <section class="section bg-default" style=" background: #FAFAFA; padding: 50px 0;">
    <div class="container">
      <div class="row row-50 justify-content-center align-items-center text-center">
        <div class="col-md-12 flex-column justify-content-center">
          <h2 class="">Aplicam reguli pentru situatiile</h2>
          <div class="heading-3 ls-ng-1 offset-xl-t-40 text-gray-600">
            <ul class="list-marked col-md-4 offset-md-4" style="font-size: 20px;">
              <li class="li-alternate">un utilizator nu raspunde intr-o saptamana</li>
              <li class="li-alternate">vocabular nepotrivit</li>
              <li class="li-alternate">persoane fara interes pentru o relatie</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="section sectionbottom" style="background: #fafafa; padding: 50px 0px !important;">
    <div class="range">
      <div class="col-md-12">
        <div class="cell-inner section-lg2 text-center text-sm-left">
          <h2 class="text-center text-xl-left wow fadeIn">Persoane compatibile <br> <span style="font-size: 25px;">- in
              3 pasi -</span></h2>
          <!-- <p class="text-center text-xl-left wow fadeIn" data-wow-delay=".05s"> 
              In fiecare zi veti avea acces la 3 profile compatibile. <br>
              Persoanele care nu raspund intr-o saptamana, vor avea contul blocat si va trebui sa achite o taxa pentru deblocare.</p> -->
          <!-- Owl Carousel-->
          <div class="custom owl-1 list-group-1 mt-lg-50  row col-md-12x">
            <article class="col-md-4 wow fadeIn">
              <img src="assets/images/profile.jpg" />
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal">Completeaza profilul</h3>
                <p>Creeaza-ti profilul gratuit. Aloca-ti timpul necesar. Sansele tale de a-ti gasi persoana compatibila
                  (prietenie sau relatie)
                  vor creste semnificativ.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn" data-wow-delay=".025s">
              <img src="assets/images/safe.png" />
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Eveniment
                </h3>
                <p>

                  Accesează secțiunea <a [routerLink]="['/users']" style="color: blue;">Membri</a> pentru a selecta persoanele posibil compatibile (Like). După ce au fost selectate persoanele care au primit Like reciproc, va fi organizat un eveniment cu un număr egal de fete și băieți.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn" data-wow-delay=".05s">
              <img src="assets/images/result.jpg" />
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Rezultate</h3>
                <p>
                  La eveniment vei avea ocazia să cunoști persoanele cu potențial mare de compatibilitate, oferindu-ți șansa de a interacționa direct și de a descoperi conexiuni autentice într-un cadru plăcut și relaxant.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="section cta-section position-relative section-sm context-dark bg-img-2">
      <div class="container">
        <div class="row justify-content-lg-end justify-content-center">
          <div class="col-md-8 col-lg-5 d-flex flex-column justify-content-center wow fadeInRight">
            <h2>Premium Membership</h2>
            <p class="text-white-7">Enjoy the benefits of our dating services membership! Use the psychological matching to find the best people and experience other great bonuses.</p>
            <div class="unit cta-unit unit-spacing-md flex-column flex-sm-row align-items-center offset-xl-t-40">
              <div class="unit-left"><a class="button button-md button-light" href="#">view all options</a></div>
              <div class="unit-body"><span class="text-white-7">14 days for free</span></div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

</ng-container>

<app-footerpage></app-footerpage>