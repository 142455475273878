
<app-headerpage></app-headerpage>

<div *ngIf="!currentUser || !loaded" style="min-height: 100vh;">
  <img src="assets/images/loading.gif" class="imgloading" />
</div>

<ng-container *ngIf="currentUser && loaded">

<div class="listtop" style="display: flex;">

  <section class="section sectiontop section-lg bg-default text-center col-md-6" style="    padding: 20px; 
  background: #ffe5ce;">
    <div class="container" style="padding: 20px 0px !important;">
      <div class="jumbotron-2-blockx" style="padding: 10px 0px 0px 0px;">
        <div *ngIf="!list || list.length == 0" class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Participare la eveniment</h2>
          <p class="text-gray-700 wow fadeIn" data-wow-delay=".025s"
            style="visibility: visible; animation-delay: 0.025s; animation-name: fadeIn;"><span
              style="max-width: 850px;"> Creeaza-ti profilul gratuit. Aloca-ti timpul necesar. Sansele tale de a-ti gasi persoana compatibila (prietenie sau relatie) vor creste semnificativ. <br> Parcurgeti lista membrilor, <br class="isnotmobile"> vizualizati profilul si oferiti Like la persoanele  posibil compatibile.</span></p>
          <br>
          <button class="btn btn-success" [routerLink]="['/users']">Lista membri</button>
          <button class="btn btn-info" [routerLink]="['/profile']" style="margin-left: 10px;">Completeaza profilul</button>
          <!-- data-toggle="modal" data-target="#profile" -->
        </div>
        <div *ngIf="list && list.length > 0" class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">{{list.length}} compatibilitati
          </h2>
          <p class="text-gray-700 wow fadeIn" data-wow-delay=".025s"
            style="visibility: visible; animation-delay: 0.025s; animation-name: fadeIn;"><span
              style="max-width: 850px;"> Dupa completarea profilului, cautam atat in baza de date cat si prin
              partenerii nostri. </span></p>
          <div class="row row-30 row-xl-50 mt-md-45 mt-xxl-60">
            <div class="col-sm-6 col-lg-4" *ngFor="let item of list">
              <!-- Tour 3-->
              <article class="tour-3 bg-image context-dark wow fadeIn"
                style="visibility: visible; animation-name: fadeIn;"
                [ngStyle]="{'background-image': item['profile'] && item['profile']['img']?'url('+item['profile']['img']+')':'url(assets/images/noperson.jpg)'}">
                <div class="tour-3-inner">
                  <div class="tour-3-main">
                    <h3 class="tour-3-title"><a data-toggle="modal"
                        data-target="#profileuser">{{item['profile']['nume']}}</a></h3>
                    <ul class="tour-3-meta">
                      <!-- <li><span class="icon mdi material-design-clock100"></span><span>active 10 days ago</span></li> -->
                      <!-- <li data-toggle="modal" data-target="#chat"><i class="fa fa-comments-o"
                          aria-hidden="true"></i><span>chat</span></li> -->
                    </ul>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="false" class="row">
        <div class="col-12">
          <!-- <h2>Mai multe persoane compatibile</h2> -->
          <!-- <p class="offset-xl-t-35">Cautam atat in baza de date cat si prin reprezentantii nostri.</p> -->
        </div>
      </div>
      <div *ngIf="false" class="row row-30 row-md-50 justify-content-center justify-content-lg-start">
        <!-- <div class="col-md-6">
          <blockquote class="quote-classic">
            <div class="quote-classic-inner" style="text-align: center;">
              <div class="quote-classic-header" style="display: flex;justify-content: center;">
                <div class="quote-classic-profile">
                  <cite class="quote-classic-cite heading-3 ls-ng-1">Completeaza profil</cite>
                </div>
              </div>
              <div class="quote-classic-text" style="text-align: center;">
                <p>Completeaza profilul. Aloca-ti timpul necesar. Sansele tale de a-ti gasi persoana potrivita vor creste semnificativ.</p>
              </div>
              <br>
              <button class="btn btn-info" data-toggle="modal" data-target="#profile">click aici</button>
            </div>
          </blockquote>
        </div> -->
        <div class="col-md-6">
          <blockquote class="quote-classic">
            <div class="quote-classic-inner" style="text-align: center;">
              <div class="quote-classic-header" style="display: flex;justify-content: center;">
                <div class="quote-classic-profile">
                  <cite class="quote-classic-cite heading-3 ls-ng-1">Comunitate</cite>
                </div>
              </div>
              <div class="quote-classic-text" style="text-align: center;">
                <p>Puteti forma un grup de persoane singure, si noi va ajutam sa organizati evenimente/discutii cu alte
                  grupuri <br> ( <span style="color: orangered;">online in perioada de pandemie</span> )</p>
              </div>

            </div>
          </blockquote>
        </div>
        <div class="col-md-6">
          <blockquote class="quote-classic">
            <div class="quote-classic-inner" style="text-align: center;">
              <div class="quote-classic-header" style="display: flex;justify-content: center;">
                <div class="quote-classic-profile">
                  <cite class="quote-classic-cite heading-3 ls-ng-1">Referinte</cite>
                </div>
              </div>
              <div class="quote-classic-text" style="text-align: center;">
                <p>Va cautam mai multe persoane compatibile, daca trimiteti link-ul de mai jos altor persoane singure.
                </p>
              </div>

              <p> <input *ngIf="currentUser" id="copylink" value="vreaurelatie.ro/auth/invite/{{currentUser['uid']}}"
                  class="copyinput readonly"> </p>
              <button class="btn btn-info" (click)="copylink()">copiaza link <i class="fa fa-copy"></i></button>
              <span *ngIf="msgcopy" style="margin-left: 10px;">{{msgcopy}}</span>

            </div>
          </blockquote>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lgx bg-default text-center col-md-6"
  style="background: #e0f3f1; padding: 50px; margin-top: 0px !important;">
  <div class="container" style="padding: 20px 0px !important;">
    <div class="jumbotron-2-blockx" style="padding: 10px 0px 0px 0px;">
      <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
        <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Cunoaste persoane compatibile!</h2>
        <p class="bigx"> Comunitatea Precis introduce un nou tip de eveniment! <br class="isnotmobile">

          Accesează secțiunea Membri pentru a selecta persoanele posibil compatibile (Like). <br class="isnotmobile"> După ce au fost selectate persoanele care au primit Like reciproc, <br class="isnotmobile"> va fi organizat un eveniment cu un număr egal de fete și băieți.
        </p>
        <br>
        <button class="btn btn-info" [routerLink]="['/users']">Lista membri</button>
        <!-- <div class="row fields">
          <div class="col-md-4 offset-md-4"><a class="btn btn-info" href="blog/evenimente.html" target="_blank"
              style="background: cornflowerblue; border-radius: 50px; margin-top: -15px;">Mai multe detalii</a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>

</div>
  

  <section class="section section-lgx bg-default text-center" style="background: #ffe5e4; padding: 20px 0px 40px 0px; display: none;">
    <div class="container" style="padding: 20px 0px !important;">
      <div class="jumbotron-2-blockx" style="padding: 10px 0px 0px 0px;">
        <div *ngIf="!list || list.length == 0" class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Persoanele care vor sa te cunoasca <span
              style="">gratuit</span></h2>

          <div class="row fields">
            <div class="col-md-4 offset-md-4">
              <button class="btn btn-info" [routerLink]="['/users']"
                style="background: cornflowerblue; border-radius: 50px; margin-top: -15px;">vezi lista</button>
            </div>

            <!-- <div class="col-md-4 offset-md-2">
              <label>Eveniment</label>
              <select>
                <option value="">selecteaza</option>
                <option value="e1">e1</option>
                <option value="e2">e2</option>
                <option value="e3">e3</option>
              </select>
            </div> -->
            <!-- <div class="col-md-4">
              <button class="btn btn-info" [routerLink]="['/user']" style="background: cornflowerblue; border-radius: 50px; margin-top: 15px;">Participa la evenimentul selectat</button>
            </div>  -->
          </div>

        </div>
      </div>
      <div *ngIf="false" class="row">
        <div class="col-12">
          <!-- <h2>Mai multe persoane compatibile</h2> -->
          <!-- <p class="offset-xl-t-35">Cautam atat in baza de date cat si prin reprezentantii nostri.</p> -->
        </div>
      </div>
      <div *ngIf="false" class="row row-30 row-md-50 justify-content-center justify-content-lg-start">
        <!-- <div class="col-md-6">
          <blockquote class="quote-classic">
            <div class="quote-classic-inner" style="text-align: center;">
              <div class="quote-classic-header" style="display: flex;justify-content: center;">
                <div class="quote-classic-profile">
                  <cite class="quote-classic-cite heading-3 ls-ng-1">Completeaza profil</cite>
                </div>
              </div>
              <div class="quote-classic-text" style="text-align: center;">
                <p>Completeaza profilul. Aloca-ti timpul necesar. Sansele tale de a-ti gasi persoana potrivita vor creste semnificativ.</p>
              </div>
              <br>
              <button class="btn btn-info" data-toggle="modal" data-target="#profile">click aici</button>
            </div>
          </blockquote>
        </div> -->
        <div class="col-md-6">
          <blockquote class="quote-classic">
            <div class="quote-classic-inner" style="text-align: center;">
              <div class="quote-classic-header" style="display: flex;justify-content: center;">
                <div class="quote-classic-profile">
                  <cite class="quote-classic-cite heading-3 ls-ng-1">Comunitate</cite>
                </div>
              </div>
              <div class="quote-classic-text" style="text-align: center;">
                <p>Puteti forma un grup de persoane singure, si noi va ajutam sa organizati evenimente/discutii cu alte
                  grupuri <br> ( <span style="color: orangered;">online in perioada de pandemie</span> )</p>
              </div>

            </div>
          </blockquote>
        </div>
        <div class="col-md-6">
          <blockquote class="quote-classic">
            <div class="quote-classic-inner" style="text-align: center;">
              <div class="quote-classic-header" style="display: flex;justify-content: center;">
                <div class="quote-classic-profile">
                  <cite class="quote-classic-cite heading-3 ls-ng-1">Referinte</cite>
                </div>
              </div>
              <div class="quote-classic-text" style="text-align: center;">
                <p>Va cautam mai multe persoane compatibile, daca trimiteti link-ul de mai jos altor persoane singure.
                </p>
              </div>

              <p> <input *ngIf="currentUser" id="copylink" value="vreaurelatie.ro/auth/invite/{{currentUser['uid']}}"
                  class="copyinput readonly"> </p>
              <button class="btn btn-info" (click)="copylink()">copiaza link <i class="fa fa-copy"></i></button>
              <span *ngIf="msgcopy" style="margin-left: 10px;">{{msgcopy}}</span>

            </div>
          </blockquote>
        </div>
      </div>
    </div>
  </section>

  <!-- <section>
    <img src="assets/images/loving2.jpg" style="width: 100%;" />
  </section> -->

  <section class="section" style="background: #FFF; display: none;">
    <div class="range">
      <div class="col-md-12">
        <div class="cell-inner section-lg2 text-center text-sm-left">
          <h2 class="text-center text-xl-left wow fadeIn">Cunoaste persoane noi! <br> <span style="font-size: 25px;">-
              prietenie sau relatie -</span></h2>
          <!-- <p class="text-center text-xl-left wow fadeIn" data-wow-delay=".05s">
            Identificam pentru evenimente persoane noi si compatibile
          </p> -->
          <!-- Persoanele care nu raspund intr-o saptamana, vor avea contul blocat si va trebui sa achite o taxa pentru deblocare. -->
          <!-- Owl Carousel-->
          <div class="custom owl-1 list-group-1 mt-lg-50 row col-md-12x">
            <article class="col-md-4 wow fadeIn">
              <div class="icon lg-1-item-icon mdi material-design-compass106"></div>
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Cautare</h3>
                <p>Ca sa putem identifica persoane compatibile, este necesar sa completati profilul. Cautam atat in baza
                  de
                  date cat si prin partenerii nostri.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn">
              <div class="icon lg-1-item-icon mdi mdi-account-search"></div>
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Referinte</h3>
                <p>Pentru fiecare persoana care isi creaza cont, folosind link-ul Dvs. de referinta din profil, ai
                  <span style="color: orangered;">reducere 10 RON la evenimentele partenerilor</span>.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn" data-wow-delay=".05s">
              <div class="icon lg-1-item-icon mdi mdi-comment-processing"></div>
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Evenimente</h3>
                <p>Incurajăm persoanele active și sprijinim organizatorii de evenimente. Prin implicarea lor contribuie la succesul fiecărui moment special.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section sectionbottom" style="background: #fafafa; padding: 5cqmax 0px !important; margin-top: 0px !important;">
    <div class="range">
      <div class="col-md-12">
        <div class="cell-inner section-lg2 text-center text-sm-left">
          <h2 class="text-center text-xl-left wow fadeIn">Persoane compatibile <br> <span style="font-size: 25px;">- in
              3 pasi -</span></h2>
          <!-- <p class="text-center text-xl-left wow fadeIn" data-wow-delay=".05s"> 
              In fiecare zi veti avea acces la 3 profile compatibile. <br>
              Persoanele care nu raspund intr-o saptamana, vor avea contul blocat si va trebui sa achite o taxa pentru deblocare.</p> -->
          <!-- Owl Carousel-->
          <div class="custom owl-1 list-group-1 mt-lg-50  row col-md-12x">
            <article class="col-md-4 wow fadeIn">
              <img src="assets/images/profile.jpg" />
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal">Completeaza profilul</h3>
                <p>Creeaza-ti profilul gratuit. Aloca-ti timpul necesar. Sansele tale de a-ti gasi persoana compatibila
                  (prietenie sau relatie)
                  vor creste semnificativ.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn" data-wow-delay=".025s">
              <img src="assets/images/safe.png" />
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Eveniment
                </h3>
                <p>
                  Accesează secțiunea <a [routerLink]="['/users']" style="color: blue;">Membri</a> pentru a selecta persoanele posibil compatibile (Like). După ce au fost selectate persoanele care au primit Like reciproc, va fi organizat un eveniment cu un număr egal de fete și băieți.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn" data-wow-delay=".05s">
              <img src="assets/images/result.jpg" />
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Rezultate</h3>
                <p>
                  La eveniment vei avea ocazia să cunoști persoanele cu potențial mare de compatibilitate, oferindu-ți șansa de a interacționa direct și de a descoperi conexiuni autentice într-un cadru plăcut și relaxant.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section bg-default" style=" background: #FAFAFA; padding: 50px 0;">
    <div class="container">
      <div class="row row-50 justify-content-center align-items-center text-center">
        <div class="col-md-12 flex-column justify-content-center">
          <h2 class="">Restrictionam utilizatorii pentru situatiile:</h2>
          <div class="heading-3 ls-ng-1 offset-xl-t-40 text-gray-600">
            <ul class="list-marked col-md-4 offset-md-4" style="font-size: 20px;">
              <li class="li-alternate">un utilizator nu raspunde intr-o saptamana</li>
              <li class="li-alternate">vocabular nepotrivit</li>
              <li class="li-alternate">persoane fara interes pentru o relatie</li>              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

</ng-container>

<app-footerpage></app-footerpage>

<div class="modal profile" id="profile">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Profile</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span id="profileclose" aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body" style="overflow: auto;padding: 20px 50px;">
        <app-profile [modal]="true"></app-profile>
      </div>

    </div>
  </div>
</div>

<div class="modal profileuser" id="profileuser">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Profile</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span id="chatclose" aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body" style="overflow: hidden;padding: 20px 50px;">
        <app-profileuser></app-profileuser>
      </div>

    </div>
  </div>
</div>


<div class="modal chat" id="chat">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Chat</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span id="chatclose" aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body" style="overflow: hidden;padding: 20px 50px;">
        <app-chat></app-chat>
      </div>

    </div>
  </div>
</div>